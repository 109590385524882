import AdminContainer from '../components/AdminMenu/AdminContainer'

const AdminMenu = () => {
  return (
    <>
      <AdminContainer />
    </>
  )
}

export default AdminMenu
