import AdminLoginContainer from '../components/AdminLogin/AdminLoginContainer'

const AdminLogin = () => {
  return (
    <>
      <AdminLoginContainer />
    </>
  )
}

export default AdminLogin
